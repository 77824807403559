import {cn} from '~/design-system/utils'

type HgComposableDetailsTitleProps = {
  title?: React.ReactNode | string
  body: string | React.ReactNode
  classNames?: {
    title?: string
    body?: string
  }
}

const HgComposableDetailsTitle: React.FC<HgComposableDetailsTitleProps> = ({
  title,
  body,
  classNames,
}) => (
  <div className="flex flex-col gap-s2">
    <p className={cn('text-text-default arcadia-heading-9', classNames?.title)}>
      {title}
    </p>
    {typeof body === 'string' ? (
      <p className={cn('text-text-subdued arcadia-subheading-5', classNames?.body)}>
        {body}
      </p>
    ) : (
      <div
        className={cn('text-text-subdued arcadia-subheading-5', classNames?.body)}
      >
        {body}
      </div>
    )}
  </div>
)

export default HgComposableDetailsTitle
