import React from 'react'
import {grid, gridItem} from '~/design-system/foundations'

export type HgComposableDetailsSectionProps = {
  title: string | React.ReactNode
  children: React.ReactNode
}

const HgComposableDetailsSection: React.FC<HgComposableDetailsSectionProps> = ({
  title,
  children,
}) => (
  <div className="w-full pt-s9">
    <div className={grid({className: 'mx-auto'})}>
      <div
        className={gridItem({
          size: 'max',
          className:
            'grid grid-cols-1 gap-s6 border-t border-border-subdued pt-s4 lg:grid-cols-12',
        })}
      >
        <h2 className="arcadia-subheading-5 lg:col-span-4">{title}</h2>
        <div className="flex flex-col gap-y-s6 lg:col-span-8">{children}</div>
      </div>
    </div>
  </div>
)

export default HgComposableDetailsSection
