'use client'

import React, {useEffect, useId, useRef, useState} from 'react'
import {tv, type VariantProps} from 'tailwind-variants'
import {Button} from '~/design-system/foundations'
import {textLink} from '../../HgTextLink'

const hgBodyVariants = tv({
  base: 'overflow-hidden text-text-subdued transition-all duration-300 ease-out arcadia-body-2',
  variants: {
    expanded: {
      true: 'line-clamp-none',
      false: 'line-clamp-[8] md:line-clamp-6 xl:line-clamp-5 3xl:line-clamp-4',
    },
  },
  defaultVariants: {
    expanded: false,
  },
})

type HgComposableDetailsBodyProps = VariantProps<typeof hgBodyVariants> & {
  title: React.ReactNode | string
  body: React.ReactNode | string
}

const HgComposableDetailsBody: React.FC<HgComposableDetailsBodyProps> = ({
  title,
  body,
}) => {
  const {linkWrapper} = textLink({interaction: 'underline'})
  const [isExpanded, setIsExpanded] = useState(false)
  const [isTruncatable, setIsTruncatable] = useState(false)
  const textRef = useRef<HTMLParagraphElement>(null)
  const bodyId = useId()
  const buttonId = useId()

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const isTruncated =
          textRef.current.scrollHeight > textRef.current.clientHeight
        setIsTruncatable(isTruncated)
      }
    }

    const resizeObserver = new ResizeObserver(checkTruncation)

    if (textRef.current) {
      resizeObserver.observe(textRef.current)
    }

    checkTruncation()

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const toggleExpanded = () => {
    setIsExpanded(prev => !prev)
  }

  return (
    <div className="flex flex-col gap-s2">
      <h3 className="text-text-default arcadia-heading-9">{title}</h3>
      <div className="relative">
        <p
          id={bodyId}
          ref={textRef}
          className={hgBodyVariants({expanded: isExpanded})}
        >
          {body}
        </p>
      </div>
      {(isTruncatable || isExpanded) && (
        <Button
          id={buttonId}
          onClick={toggleExpanded}
          aria-controls={bodyId}
          aria-expanded={isExpanded}
          className={linkWrapper({class: 'arcadia-ui-1'})}
        >
          {isExpanded ? 'See Less' : 'See All'}
        </Button>
      )}
    </div>
  )
}

export default HgComposableDetailsBody
